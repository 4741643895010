/*-------------------------------------------------------------*/
/* Login css
/*-------------------------------------------------------------*/
body {
    /* background: #fff; */
}

.login_wrraper {
    width: 100%;
    height: 100%;
    position: relative;
}

.logo-wrapper {
    position: absolute;
    top: 20px;
    left: 40px;
}

.logo-slogan {
    text-align: center;
}

.login-top-info {
    position: absolute;
    top: 20px;
    right: 40px;
    overflow: hidden;
}

.lti-tel {
    font-size: 13px;
    float: left;
    margin: 10px 30px 0 0;
    font-weight: bold;
}

.lti-tel i {
    vertical-align: middle;
    margin-right: 3px;
}

.lti-assistance {
    text-align: center;
    float: left;
}

.lti-assistance span {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    height: 41px;
    background: #E5E5E5;
    color: #8f8f8f;
    font-size: 14px;
    font-weight: bold;
    line-height: 37px;
    position: relative;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0 20px;
    float: left;
}

.lti-assistance span:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 5px;
    border-color: transparent transparent transparent #f0f0f0;
    position: absolute;
    right: -4px;
    top: 50%;
    margin-top: -4.5px;
    z-index: 99;
}

.lti-assistance span.colored {
    background: #83CBCC;
    color: #fff;
    padding: 0 30px;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.lti-assistance span.colored:after {
    border-width: 0;
}

.lti-assistance span i {
    vertical-align: middle;
    margin-right: 8px;
}

.login-bottom-info {
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    right: 40px;
    z-index: 999999;
}

.lbi-bloc {
    font-size: 13px;
    color: #fff;
    float: left;
    margin-left: 30px;
    line-height: 23px;
}

.lbi-bloc i {
    vertical-align: middle;
    margin-right: 3px;
}

.login_bg {
    height: 50%;
    width: 100%;
    background: #02C092;
    position: absolute;
    left: 0;
    bottom: 0;
}

.login_container {
    width: 365px;
    position: absolute;
    left: 50%;
    margin-left: -182px;
    top: -70%;
    background: #eeeff3;
}

.login_ct {
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
}

/* .login_ct::before {
    content: "";
    width: 100%;
    height: 2px;
    background: linear-gradient(to right,
            #02C092 25%,
            #afcb4f 25%,
            #afcb4f 50%,
            #81cacb 50%,
            #81cacb 75%,
            #3c3d3d 75%);
    position: absolute;
    bottom: 0;
    left: 0;
} */

/* .login_ct::after {
    content: "";
    width: 3.5px;
    height: 100%;
    background: linear-gradient(to top,
            #02C092 25%,
            #afcb4f 25%,
            #afcb4f 50%,
            #81cacb 50%,
            #81cacb 75%,
            #3c3d3d 75%);
    position: absolute;
    top: 0;
    right: 0;
} */

.login {
    padding: 20px 35px;
}

.login_head {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'walkway_semiboldregular';
}

/* .horizontal_line {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right,
    #02C092 25%,
    #afcb4f 25%,
    #afcb4f 50%,
    #81cacb 50%,
    #81cacb 75%,
    #3c3d3d 75%);
    margin-bottom: 30px;
} */

.login_head strong {
    font-family: 'walkway_blackregular';
    font-size: 24px;
}

.login_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login_txt {
    font-size: 15px;
    margin-bottom: 20px;
}

.form_row {
    margin-bottom: 7px;
}

.form_row:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.input {
    height: 33px;
    width: 100%;
    color: #5a5a5a;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #bbb;
    padding: 0 10px;
    margin: 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.input:hover,
.input:focus {
    border-color: #02C092;
}

.checkbox {
    font-weight: 600;
}

.checkbox input {
    display: none;
}

.checkbox .checked {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #dedede;
    border: 1px solid #dedede;
    vertical-align: middle;
    margin-right: 12px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.checkbox input:checked+.checked {
    background: #02C092;
    border: 1px solid #02C092;
    position: relative;
}

.checkbox input:checked+.checked:after {
    content: '';
    display: block;
    width: 13px;
    height: 9px;
    background: transparent url(../images/sprite_5.png) -322px -96px no-repeat;
    position: absolute;
    top: 4px;
    left: 2px;
}

.checkbox:hover .checked {
    border: 1px solid #02C092;
}

.submit {
    width: 100%;
    display: block;
    height: 33px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    background: #02C092;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.submit:hover {
    background: #0ca57f;
}

.submit:active {
    background: #0ca57f;
}

.pass_strong {
    height: 10px;
    background: #f0f0f0;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.pass_strong span {
    display: block;
    height: 10px;
    background: #83CBCC;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.login_error {
    color: #dc0930;
    border: 2px solid #dc0930;
    background: #ffedf0;
    padding: 12px 8px;
    margin-top: 20px;
    text-align: center;
}

.login_footer {
    background: #eeeff3;
    min-height: 57px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.login_footer p {
    font-weight: 600;
    padding: 0 35px 20px 35px;
}

.login_footer p a {
    font-weight: 600;
}

.left {
    font-size: 11px;
    font-weight: bold;
    float: left;
}

.right {
    font-size: 11px;
    font-weight: bold;
    float: right;
}

.login_notif {
    padding: 60px 0;
}

.login_notif p {
    font-size: 15px;
    text-align: center;
    padding: 0 35px;
}

.login_notif p.big_txt {
    font-size: 27px;
    font-weight: bold;
}

.sprite-succes-password,
.sprite-404 {
    display: block;
    margin: 0 auto 20px;
}