.active {
    padding: 3px 6px 6px 6px;
    /* border-bottom: 4px solid #00C389 !important; */
    background-color: #14a177c5 !important;
    color: #fff !important;
    border-radius: 3px;
}

.active:hover>li>.Text {
    border-bottom: inset;
}